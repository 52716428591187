import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import OurWorkslef from "../components/hire/OurWorkself";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `IoT Design Agency`,
    text: "IoT Design Services",
    content: "Our IoT Design Agency excels in creating innovative solutions and offers comprehensive IoT App Design Services, ensuring seamless integration and functionality across all devices. Our expert team optimizes processes, enhances user experiences, and develops cutting-edge IoT products.",
    list: [
      'Simplifying Complex Ecosystems',
      'User-Centric Designs',
      'End-to-end IoT Services'
    ]
  };
  const ourData = {
    title: `Clients Feedback On Our <span class='h1-span'>IoT UI UX Design Services</span>`,
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['visa.webp'],
        stars: 5,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
    ]
  };
  const commonContent = {
    title: "Unlock Business Potential <span class='h1-span'>With Our IoT Design Agency</span>",
    content: [
      {
        title: "10+ Team of IoT Experts",
        text: "Our team of 10+ experts creates IoT design solutions tailored to your business, maximizing the potential of IoT technology to drive growth and innovation.",
      },
      {
        title: "7+ Years of Expertise",
        text: "With 7+ years of expertise, we help businesses navigate the complexities, providing reliable and future-proof solutions with our IoT app design services.",
      },
      {
        title: "50+ Successful IoT Projects",
        text: "Having successfully completed 50+ IoT projects, we help businesses reduce risk and accelerate the time to market for their IoT initiatives with our IoT web app designs.",
      },
      {
        title: "90% Project Success Rate",
        text: "Our IoT UI UX design services have showcased tangible benefits to businesses, increasing efficiency, cost savings, and enhanced customer satisfaction.",
      },
    ],
  };
  const commonContent1 = {
    title: "Experience The Advantages of Our IoT Design Services",
    content: [
      {
        title: "Customized IoT Solutions",
        text: "Our IoT design services provide tailored solutions to meet your business's unique needs. We ensure that every project aligns perfectly with your industry requirements.",
      },
      {
        title: "User-Friendly Interfaces",
        text: "Through our expert IoT UI UX design services, we create intuitive and engaging interfaces that enhance user experience and boost customer satisfaction and retention.",
      },
      {
        title: "Real-Time Data Insights",
        text: "Our IoT solutions offer real-time data collection and analysis, providing actionable insights that help optimize performance and drive informed decision-making.",
      },
      {
        title: "Future-Proof IoT Solutions",
        text: "We stay ahead of technological advancements to ensure our IoT design services are future-proof and adaptable to new developments.",
      },
      {
        title: "Customizable Dashboards",
        text: "Our IoT app design services include customizable dashboards that provide clear, comprehensive views of your IoT data, enabling easy tracking, analysis, and reporting.",
      },
      {
        title: "Interoperability",
        text: "Our IoT design and development ensure seamless integration with your existing systems, enhancing interoperability and creating a cohesive and efficient IoT ecosystem.",
      },
    ],
  };
  const Questions = [
    "What services does your IoT Design Agency offer?",
    "What industries do you serve with your IoT design services?",
    "What is the typical timeline for an IoT project?",
    "What makes your IoT UI UX design services unique?",
    "Can you provide examples of successful IoT projects you've completed?"
  ];
  const faqData = [
    {
        para: [
            `Our IoT Design Agency offers a comprehensive range of services, including IoT design and development, IoT app design services, IoT UI UX design services, and IoT web app design. We provide end-to-end solutions from concept to deployment and ongoing support, ensuring seamless integration and functionality.`
        ]
    },
    {
      para: [
        `We serve various industries, including healthcare, manufacturing, agriculture, retail, and smart cities. Our expertise allows us to deliver specialized IoT solutions that address the specific challenges and opportunities within each industry.`
      ],
    },
    {
      para: [
        `The timeline varies based on its complexity and scope. Generally, our IoT design services follow a structured process that includes initial consultation, design, development, testing, and deployment. We provide a detailed timeline during the project planning phase to ensure transparency and alignment with your expectations.`
      ]
    },
    {
      para: [
        `Our IoT UI UX design services focus on creating intuitive, user-friendly interfaces that enhance the user experience. We employ the latest design principles and user feedback to ensure our interfaces are engaging, easy to navigate, and meet the needs of your end-users.`
      ],
    },
    {
        para: [
          `Yes, we have a portfolio of successful IoT projects across various industries. <a href='/contact-us/' title='contact us' target='_blank'>contact us</a> and we will be happy to share the completed projects with you.`
        ],
      }
  ];
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What services does your IoT Design Agency offer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Our IoT Design Agency offers a comprehensive range of services, including IoT design and development, IoT app design services, IoT UI UX design services, and IoT web app design. We provide end-to-end solutions from concept to deployment and ongoing support, ensuring seamless integration and functionality."
              }
            },{
              "@type": "Question",
              "name": "What industries do you serve with your IoT design services?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We serve various industries, including healthcare, manufacturing, agriculture, retail, and smart cities. Our expertise allows us to deliver specialized IoT solutions that address the specific challenges and opportunities within each industry."
              }
            },{
              "@type": "Question",
              "name": "What is the typical timeline for an IoT project?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The timeline varies based on its complexity and scope. Generally, our IoT design services follow a structured process that includes initial consultation, design, development, testing, and deployment. We provide a detailed timeline during the project planning phase to ensure transparency and alignment with your expectations."
              }
            },{
              "@type": "Question",
              "name": "What makes your IoT UI UX design services unique?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Our IoT UI UX design services focus on creating intuitive, user-friendly interfaces that enhance the user experience. We employ the latest design principles and user feedback to ensure our interfaces are engaging, easy to navigate, and meet the needs of your end-users."
              }
            },{
              "@type": "Question",
              "name": "Can you provide examples of successful IoT projects you've completed?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, we have a portfolio of successful IoT projects across various industries. Contact us, and we will be happy to share the completed projects with you."
              }
            }]
          }                                                        
        `}
      </script>
    </Helmet>
    <Layout hire="Telecom Design Agency" hotjarHire={true} footerText="Maximize your conversions with Us">
      <div>
        <HireBanner data={banner} hire pageName="Hire Conversion Rate Optimisation" />
        <section className="py-[40px]">
          <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
            <div className="lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <OurWorkslef data={ourData} hire />
        <CommonServiceComponent data={commonContent} mode={"dark"} hire />
        <CommonServiceComponent data={commonContent1} mode={"light"} hire />
        <Faq grey section Questions={Questions} faqData={faqData} des={''} />
      </div>
    </Layout>
    </>
  );
};

export default HirePage;

export const Head = () => (
  <Seo
    title="IoT Design Agency | IoT Design and Development"
    description="Experience the future of IoT with our IoT Design Agency. We design intuitive IoT UI UX that deliver personalized, engaging connected experiences."
  />
);
